import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

// External components
import { JobsContext } from './JobsContext';

export const FiltersContext = createContext();

const slugify = (str) => {
	if (str) {
		return (str
			.toLowerCase()
			.trim()
			.replace(/ø/g, 'o')
			.replace(/æ/g, 'ae')
			.replace(/å/g, 'a')
			.replace(/[^\w\s-]/g, '')
			.replace(/[\s_-]+/g, '-')
			.replace(/^-+|-+$/g, ''));
	} else {
		return "";
	}
};
const FiltersProvider = ({ children }) => {
	const [filterLoading, setFilterLoading] = useState(false);
	const { jobs } = useContext(JobsContext);
	const [jobsToShow, setJobsToShow] = useState(jobs);
	// STATES


	/* -------------------------------------------------------------------------- */
	/*                                   FILTERS                                  */
	/* -------------------------------------------------------------------------- */
	const [filters, setFilters] = useState(); // {}
	const [selectedFilters, setSelectedFilters] = useState({}); // {}
	const [hasFilters, setHasFilters] = useState(true);
	const [filtered, setFiltered] = useState(null);
	const resetFilters = useCallback(() => {
		setHasFilters(false);
		setSelectedFilters({});
		// document.querySelector('form.filter-filters').reset();
		document.querySelectorAll('.filter-checkbox--checked').forEach((el) => {
			el.classList.remove('filter-checkbox--checked');
		});
		document
			.querySelectorAll('.filter-checkbox input')
			.forEach((el) => (el.checked = false));
	}, []);

	// Create filters
	useEffect(() => {
		let isMounted = true;
		if (jobs) {
			// FILTERS

			let locations = {
				title: 'Område',
				// checkboxFilters: [],
				multiLevelCheckboxFilters: {
					"sor": {
						"value": "Sør",
						"slug": "sor",
						"subValues": {
						}
					},
					"midtnord": {
						"value": "Midt/Nord",
						"slug": "midtnord",
						"subValues": {
						}
					},
					"ost": {
						"value": "Øst",
						"slug": "ost",
						"subValues": {
						}
					},
					"vest": {
						"value": "Vest",
						"slug": "vest",
						"subValues": {
						}
					}
				},
			};
			let positionTypes = {
				title: 'Stillingstype',
				checkboxFilters: [],
				// multiLevelCheckboxFilters: {},
			};

			// Loop through jobs
			jobs.forEach((job) => {
				// console.log(job.locationData);
				// if (job.locationData) {
				// 	console.log(job);
				// }
				// if (job.locationData?.district) {
				// 	workPlaces.checkboxFilters.push({
				// 		value: job.locationData?.district,
				// 		icon: null,
				// 		label: job.locationData?.district,
				// 		invertIconOnDark: false,
				// 		count: jobs.filter(
				// 			(p) => p.locationData?.district === job.locationData?.district
				// 		).length,
				// 		view: 'block',
				// 	});
				// }
				if (job.positionType) {
					positionTypes.checkboxFilters.push({
						value: job.positionType,
						icon: null,
						label: job.positionType,
						invertIconOnDark: false,
						count: jobs.filter(
							(p) => p.positionType === job.positionType
						).length,
						view: 'block',
					});
				}

				// Create country keys in object
				if (job.locationData?.postalAreaNorway) {
					let key1 = slugify(job.locationData?.postalAreaNorway);
					let value1 = job.locationData?.postalAreaNorway;
					if (
						!locations.multiLevelCheckboxFilters[key1] // If key does not exist { multiLevelCheckboxFilters: { 'Frankrike' } }
					) {
						locations.multiLevelCheckboxFilters[key1] = {
							value: value1,
							slug: slugify(value1),
							subValues: {},
							// iconRight: getCountryFlag(job.origins.origin.country),
						}; // Create key { 'Frankrike': {value, subValues:{} } }
					}
					let key2 = slugify(job.locationData?.district);
					let value2 = job.locationData?.district;
					if (
						key2 &&
						!locations.multiLevelCheckboxFilters[
							key1
						]?.subValues.hasOwnProperty(key2)
					) {
						locations.multiLevelCheckboxFilters[key1].subValues[
							job.locationdata?.district
						] = {
							value: value2,
						}; // Create key { multiLevelCheckboxFilters: { 'Burgund': { value: 'Burgund' } }
					}
				}
			});
			// console.log(locations);
			// Remove duplicates
			const getUniques = (obj, key, sort) => {
				return {
					...obj,
					[key]: Array.from(new Set(obj[key].map(JSON.stringify)))
						.map((val) => {
							let item = JSON.parse(val);
							let count = 0;
							if (item.count) {
								count = item.count;
							} else {
								obj[key]
									.filter((p) => p.value === item.value)
									.forEach(() => (count += 1));
							}
							return { ...item, count };
						})
						.sort((a, b) => (a.count > b.count ? -1 : +1)), // Removes duplicates in checkbox filter array
				};
			};
			let f = {
			};
			if (Object.keys(locations.multiLevelCheckboxFilters)?.length > 0) {
				f = {
					...f,
					locations: locations,
				};
			}
			if (positionTypes.checkboxFilters?.length > 0) {
				f = {
					...f, positionTypes: getUniques(positionTypes, 'checkboxFilters'),
				};
			}
			if (isMounted) {
				setFilters(f);
			}
		}
		return () => {
			// 👇️ when component unmounts, set isMounted to false
			isMounted = false;
		};
	}, [jobs]);

	const filterJobs = useCallback(
		(jobs) => {
			// console.log(
			// 	jobs
			// 		.filter((j) => j.locationData)
			// 		?.map((job) => job.locationdata?.postalAreaNorway)
			// );
			const hasFilter = (key, valuesKey = 'values') =>
				selectedFilters.hasOwnProperty(key) &&
					selectedFilters[key][valuesKey]?.length > 0
					? true
					: false;

			// let positionTypesFilter = hasFilter('positionTypes');
			let locationsFilter = hasFilter('locations');
			// let districtsFilter = hasFilter('locations', 'subValues');
			// console.log({
			// 	positionTypesFilter,
			// 	locationsFilter,
			// 	districtsFilter
			// });
			if (locationsFilter
				// || districtsFilter || positionTypesFilter
			) {

				setFilterLoading(true);
				let filtered = jobs?.filter((job) => {
					const checkArrayMatch = (hasFilters, selected, valueToMatch) => {
						if (hasFilters) {
							if (Array.isArray(valueToMatch)) {
								if (valueToMatch.some((val) => selected.includes(val))) {
									return true;
								}
							} else {
								// console.log('357: ', selected.includes(valueToMatch));
								return selected.includes(valueToMatch) ? true : false;
							}
						} else {
							// No filter, return true to show all
							return true;
						}
					};
					// let matchesPositionTypes = checkArrayMatch(
					// 	positionTypesFilter,
					// 	selectedFilters['positionTypes']?.values,
					// 	job.positionType
					// );
					let matchesLocations = checkArrayMatch(
						locationsFilter,
						selectedFilters['locations']?.values,
						job.locationData?.postalAreaNorway
					);
					if (matchesLocations
						// && matchesDistricts && matchesPositionTypes
					) {
						return true;
					} else {
						return false;
					}
				});
				setFiltered(filtered);
			} else {
				setHasFilters(false);
			}
		},
		[selectedFilters]
	);

	useEffect(() => {
		const clearFilterLoading = () => {
			setFilterLoading(false);
		};
		if (filtered === jobsToShow) {
			setTimeout(clearFilterLoading, 300);
		}
		return () => {
			clearTimeout(clearFilterLoading, 300);
		};
	}, [filtered, jobsToShow]);

	useEffect(() => {
		if (filtered) setJobsToShow(filtered);
		else setJobsToShow(jobs);
	}, [filtered, jobs]);



	useEffect(() => {
		if (jobs) {
			if (hasFilters) {
				filterJobs(jobs);
			} else {
				setJobsToShow(jobs);
			}
		}
	}, [hasFilters, jobs, filterJobs]);

	// useEffect(() => {
	// 	console.table('filter', selectedFilters);
	// }, [selectedFilters,hasFilters]);

	/* -------------------------------------------------------------------------- */
	/*                                   SORTING                                  */
	/* -------------------------------------------------------------------------- */
	const defaultSort = 'byPublished';
	const [sort, setSort] = useState(defaultSort); // Sort by
	const [sortDirection, setSortDirection] = useState(false); // Sort direction
	const [itemsToSort, setItemsToSort] = useState(null);
	const reverseJobs = useCallback(() => {
		setJobsToShow((j) => [...j].reverse());
	}, [setJobsToShow]);
	const sortItems = useCallback(
		(obj) => {
			const sortByWorkplace = (arr) => {
				setJobsToShow(
					[...arr].sort(function (a, b) {
						return a.workPlace.localeCompare(b.workPlace);
					})
				);
			};
			const sortByPublished = (arr) => {
				setJobsToShow([...arr].sort((a, b) => (a.id < b.id ? +1 : -1)));
			};
			const sortByDeadline = (arr) => {
				setJobsToShow(
					[...arr].sort((a, b) =>
						a.applicationDue < b.applicationDue ? -1 : +1
					)
				);
			};
			let items = obj.items;
			if (items) {
				let arr = [...items];
				if (sort === 'byDeadline') {
					sortByDeadline(arr);
				} else if (sort === 'byWorkplace') {
					sortByWorkplace(arr);
				} else if (sort === 'byPublished') {
					sortByPublished(arr);
				} else {
					let sorted = [...arr].sort((a, b) => {
						let aVal = a.id;
						let bVal = b.id;
						if (aVal < bVal) {
							return +1;
						} else {
							return -1;
						}
					});
					setJobsToShow(sorted);
				}
			}
		},
		[sort]
	);
	useEffect(() => {
		if (sort) {
			sortItems({ items: itemsToSort });
		}
	}, [itemsToSort, sort, sortItems]);
	useEffect(() => {
		if (jobs) {
			setItemsToSort(jobs);
		}
	}, [jobs]);

	/* -------------------------------------------------------------------------- */
	/*                                   SEARCH                                   */
	/* -------------------------------------------------------------------------- */

	const [searchedJobs, setSearchedJobs] = useState(null);
	useEffect(() => {
		// Run through sort after searching
		sortItems({ items: searchedJobs });

		// Limit sorting to only search results
		setItemsToSort(searchedJobs);
	}, [searchedJobs, sortItems]);

	/* -------------------------------------------------------------------------- */
	/*                                  PIPELINE                                  */
	/* -------------------------------------------------------------------------- */
	// useEffect(() => {
	// 	if (jobs) {
	// 		setJobsToShow(jobs);
	// 	}
	// }, [jobs]);

	return <FiltersContext.Provider
		value={{
			// JOB props
			jobsToShow,
			setJobsToShow,


			// FILTER props
			filters,
			hasFilters,
			setHasFilters,
			resetFilters,
			setSelectedFilters,
			selectedFilters,
			filterLoading,
			setFilterLoading,

			// SORT props
			defaultSort,
			setSort,
			sortDirection,
			setSortDirection,
			reverseJobs,

			// SEARCH props
			setSearchedJobs,
		}}
	>{children}</FiltersContext.Provider>;
};
export default FiltersProvider;
