import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Suspense } from 'react';

// Contexts
import { DataProvider } from '~/contexts/Data';
import { HelpersProvider } from '~/contexts/Helpers';
import JobsProvider from '~/contexts/JobsContext';
import FiltersProvider from '~/contexts/FiltersContext';
import "~/styles/main.scss";
// import Loading from '~/components/Loading';

// After
import { createRoot } from 'react-dom/client';
import Spinner from './components/Spinner';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Router>
    <HelpersProvider>
      <DataProvider>
        <JobsProvider>
          <FiltersProvider>
            <Suspense fallback={
              <Spinner full />}>
              <App />
            </Suspense>
          </FiltersProvider>
        </JobsProvider>
      </DataProvider>
    </HelpersProvider>
  </Router>
);
